import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  collection,
  CollectionReference,
  doc,
  orderBy,
  query,
  setDoc,
  where,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";

import { db } from "~/integrations/firebase/firestore";
import { useAuth } from "./AuthContext";
import { Chat } from "~/types/Chat";
import { useAnalytics } from "~/context/AnalyticsProvider";
import { useLDClient } from "launchdarkly-react-client-sdk";

interface ChatsContext {
  chats: Chat[];
  reference: CollectionReference<Chat>;

  createChat(chat: Chat): Promise<string>;
}

export const ChatsContext = createContext<ChatsContext>(undefined as never);

export const useChats = () => useContext(ChatsContext);

const chatConverter = {
  toFirestore(chat: Chat) {
    return chat;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Chat {
    return snapshot.data() as unknown as Chat;
  },
};

export const ChatsProvider = ({ children }: PropsWithChildren) => {
  const { authUser } = useAuth();
  const { log } = useAnalytics();
  const ldClient = useLDClient();

  const reference = collection(db, "chats").withConverter(chatConverter);

  const userChatsQuery = useMemo(() => {
    const filtered = query(reference, where("userId", "==", authUser!.uid));
    return query(filtered, orderBy("updatedAt", "desc"));
  }, [authUser!.uid]);

  const [chats = []] = useCollectionData<Chat>(userChatsQuery);

  async function createChat(chat: Chat) {
    const ref = doc(reference, chat.id);

    await setDoc(ref, chat);

    log({
      type: "chat_created",
      payload: {
        chatId: chat.id,
        departmentId: chat.departmentId,
        messageLength: chat.messages[0]?.content?.length ?? 0,
      },
    });

    return chat.id;
  }

  return (
    <ChatsContext.Provider value={{ chats, createChat, reference }}>
      {children}
    </ChatsContext.Provider>
  );
};
